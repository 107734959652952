.exhibitors__filters {
  display: flex;
  margin-bottom: var(--space-63);
}

.exhibitors__filter:not(:last-child) {
  margin-right: var(--space-10);
}

.exhibitors__content,
.exhibitors__list {
  @include grid-base;
}

.exhibitors__content {
  position: relative;

  @include font-disp;
}

.exhibitors__content--artists {
  @include font-serif;
}


.exhibitors__list,
.exhibitors__item {
  grid-column: 1 / -1;
}

.exhibitors__list {
  row-gap: var(--space-12);
  grid-row: 1;
  z-index: 1;
}

.exhibitors__item {
  justify-self: start;

  @include text-24;

  @include tablet-portrait {
    @include text-36;
  }

  @include tablet-landscape {
    @include text-56;
  }

  @include desktop {
    @include text-63;
  }

  a {
    text-decoration: none;
  }
}

.exhibitors__images {
  position: fixed;
  top: var(--space-63);
  right: var(--margin);
  bottom: var(--margin);
  left: 50%;
  pointer-events: none;
}

.exhibitors__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  .image-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 0;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top right;
  }
}

