:root {
  /* Colors */
  --color-black: #000;
  --color-white: #fff;
  --color-red: #f32;

  /* Spacing */
  --margin: 1rem;
  --gutter: 0.75rem;

  --space-4: 0.25rem;
  --space-6: 0.375rem;
  --space-8: 0.5rem;
  --space-10: 0.625rem;
  --space-12: 0.75rem;
  --space-14: 0.875rem;
  --space-16: 1rem;
  --space-24: 1.25rem;
  --space-36: 2.25rem;
  --space-42: 2.625rem;
  --space-56: 3.5rem;
  --space-63: 3.938rem;
  --space-95: 5.938rem;
  --space-120: 7.5rem;
  --space-150: 9.375rem;
  --space-200: 12.5rem;

  @include tablet-landscape {
    /* Spacing */
    --margin: 1.25rem;
    --gutter: 1.25rem;
  }

  @include desktop-large {
    --margin: 1.04vw;
    --gutter: 1.04vw;

    --space-4: 0.21vw;
    --space-6: 0.31vw;
    --space-8: 0.42vw;
    --space-10: 0.52vw;
    --space-12: 0.63vw;
    --space-14: 0.73vw;
    --space-16: 0.83vw;
    --space-24: 1.25vw;
    --space-36: 1.88vw;
    --space-42: 2.19vw;
    --space-56: 2.92vw;
    --space-63: 3.28vw;
    --space-95: 4.95vw;
    --space-120: 6.25vw;
    --space-150: 7.81vw;
    --space-200: 10.42vw;
  }
}
