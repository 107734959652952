html,
body {
  width: 100%;
  height: 100%;
}

body {
  color: var(--color-black);

  @include font-sans;

  &.is-loading * {
    cursor: progress;
  }
}

.main {
  margin: 0 auto;
  overflow: hidden;

  @include tablet-landscape {
    margin: 0 auto;
  }
}

.main__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transform-origin: center;
  opacity: 0;
}

.main__wrapper--dark {
  // background: var(--color-black);
}

.content {
  flex: 1;
  position: relative;
  width: 100%;
  padding: var(--space-95) var(--margin) 0;

  @include tablet-portrait {
    padding: var(--space-150) var(--margin) 0;
  }

  @include tablet-landscape {
    padding: var(--space-200) var(--margin) 0;
  }
}

.content--hero {
  padding: 0 var(--margin);
}

a {
  color: currentColor;
}

.input {
  flex: 1;
  min-width: 0;
  margin: 0;
  padding: var(--space-6) 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-radius: 0;
  color: currentColor;
  border-color: currentColor;
  background: transparent;
  outline: none;

  @include font-sans;
  @include text-16;
}

.button {
  display: block;
  position: relative;
  width: max-content;
  padding: var(--space-8) var(--space-36);
  border: 1px solid var(--color-black);
  color: var(--color-black);
  border-radius: 0;
  background: transparent;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.87, 0, 0.13, 1);
  z-index: 1;

  @include font-sans;
  @include text-12;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: var(--color-black);
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.2s cubic-bezier(0.87, 0, 0.13, 1);
    z-index: -1;
  }

  @include hover-supported {
    &:hover {
      color: var(--color-white);
    }

    &:hover::before {
      transform: scaleX(1);
    }
  }

  &.button--white {
    color: var(--color-white);
    border-color: var(--color-white);
  }

  @include hover-supported {
    &.button--white:hover {
      color: var(--color-black);
    }
  }

  &.button--white::before {
    background: var(--color-white);
  }
}

.toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: max-content;
  min-width: 9rem;
  padding: var(--space-8) var(--space-12);
  border: 1px solid currentColor;
  border-radius: 9999px;
  background: transparent;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
  text-align: right;
  line-height: 1;
  cursor: pointer;

  @include font-sans;
  @include text-12;

  &::before {
    content: "";
    position: absolute;
    left: var(--space-10);
    width: 0.75rem;
    height: 0.75rem;
    border: 1px solid currentColor;
    border-radius: 9999px;
  }

  &.is-active::before {
    background: currentColor;
  }

  @include tablet-portrait {
    min-width: 11rem;
    padding: var(--space-8) var(--space-12);

    &::before {
      left: var(--space-12);
    }
  }
}

.caption {
  margin-top: var(--space-10);

  @include text-12;
}

.credits {
  @include font-serif;
  @include text-13;
}
