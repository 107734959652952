.front {
  @include grid-base;
}

.front__section {
  grid-column: 1 / -1;
}

.front__hero {
  grid-column: 1 / -1;
}

.front__section + .front__section {
  margin-top: var(--space-63);

  @include tablet-portrait {
    margin-top: var(--space-95);
  }

  @include tablet-landscape {
    margin-top: var(--space-120);
  }

  @include desktop {
    margin-top: var(--space-150);
  }
}

.front__section--highlights + .front__section {
  margin-top: var(--space-42);

  @include tablet-portrait {
    margin-top: var(--space-56);
  }

  @include desktop {
    margin-top: var(--space-63);
  }
}

.front__section--entries + .front__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    margin-top: var(--space-56);
  }

  @include desktop {
    margin-top: var(--space-63);
  }
}

.front__section--entries-single + .front__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--gutter);
  }
}

.front__section--quote {
  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.front__section--theme {
  width: calc(100% + 2 * var(--margin));
  margin-left: calc(var(--margin) * -1);
}

.front__section--entries + .front__section--theme {
  margin-top: var(--space-42);

  @include tablet-portrait {
    margin-top: var(--space-63);
  }

  @include tablet-landscape {
    margin-top: var(--space-95);
  }

  @include desktop {
    margin-top: var(--space-120);
  }
}

.front__section--theme + .front__section--entries {
  margin-top: var(--space-36);

  @include tablet-portrait {
    margin-top: var(--space-42);
  }

  @include tablet-landscape {
    margin-top: var(--space-63);
  }

  @include desktop {
    margin-top: var(--space-95);
  }
}

.front__section--footer {
  text-align: center;

  @include font-serif;
  @include text-18;

  @include tablet-portrait {
    grid-column: 2 / -2;

    @include text-24;
  }

  @include tablet-landscape {
    @include text-36;
  }

  @include desktop {
    grid-column: 3 / -3;
  }
}
