.video-player {
  width: 100%;
}

.video-player--portrait {
  @include tablet-portrait {
    @include grid-base;
  }
}

.video-player__wrapper {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: calc(var(--height) / var(--width) * 100%);
  overflow: hidden;

  @include tablet-portrait {
    .video-player--portrait & {
      grid-column: 4 / span 6;
    }
  }

  @include tablet-landscape {
    .video-player--portrait & {
      grid-column: 5 / span 4;
    }
  }
}

.video-player__media {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.video-player__content,
.video-player__pause {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 var(--margin);
  transform: translate(-50%, -50%);
  color: var(--color-white);
  z-index: 1;
}

.video-player__content__title {
  max-width: 36ch;
  margin: 0 auto;
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-24;

  @include tablet-portrait {
    @include text-36;
  }

  @include tablet-landscape {
    @include text-42;
  }
}

.video-player__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-black);
  opacity: 0.5;
  pointer-events: none;
}
