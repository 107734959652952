.hero {
  width: calc(100% + 2 * var(--margin));
  margin-left: calc(var(--margin) * -1);
  margin-bottom: var(--space-24);
  height: calc(var(--vh, 1vh) * 90);
  position: relative;
  background: var(--color-black);
}

.hero__media {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero__media__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.hero--vip {
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 var(--margin);
    text-align: center;
    color: var(--color-white);

    @include font-serif;

    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
  }

  .hero__content__heading {
    margin-bottom: var(--space-10);

    @include text-36;

    @include tablet-portrait {
      @include text-56;
    }

    @include tablet-landscape {
      @include text-63;
    }

    @include desktop {
      margin-bottom: var(--space-24);

      @include text-95;
    }
  }

  .hero__content__text {
    max-width: 48ch;

    @include text-18;

    @include tablet-portrait {
      @include text-24;
    }

    @include tablet-landscape {
      @include text-36;
    }
  }
}

.hero--2022-v1,
.hero--2023,
.hero--2024 {
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);

    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
  }

  .hero__content__title {
    margin-top: -0.15em;
    font-size: 12.5vw;
    text-align: center;

    @include font-serif;

    @include tablet-landscape {
      font-size: 17.5vh;
    }
  }

  .hero__content__preview,
  .hero__content__year {
    position: absolute;
    bottom: var(--margin);

    @include font-disp;
    @include text-24;

    @include tablet-portrait {
      @include text-42;
    }

    @include tablet-landscape {
      @include text-56;
    }
  }

  .hero__content__preview {
    left: var(--margin);
  }

  .hero__content__year {
    right: var(--margin);
  }
}

.hero--2021-v1 {
  .hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);

    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
  }

  .hero__content__title {
    margin-top: -0.15em;
    font-size: 12.5vw;
    text-align: center;

    @include font-serif;

    @include tablet-landscape {
      font-size: 17.5vh;
    }
  }

  .hero__content__preview,
  .hero__content__year {
    position: absolute;
    bottom: var(--margin);

    @include font-disp;
    @include text-24;

    @include tablet-portrait {
      @include text-42;
    }

    @include tablet-landscape {
      @include text-56;
    }
  }

  .hero__content__preview {
    left: var(--margin);
  }

  .hero__content__year {
    right: var(--margin);
  }
}

.hero--2021-v2 {
  .hero__content {
    color: var(--color-white);

    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }

    @include desktop {
      padding-top: var(--space-42);
    }
  }

  .hero__content__top,
  .hero__content__bottom {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }

  .hero__content__top {
    height: 45%;
    justify-content: flex-end;
    padding-left: 3.5vmin;

    @include tablet-portrait {
      padding-left: 1.25vmin;
    }

    @include desktop {
      justify-content: flex-start;
      height: 50%;
    }
  }

  .hero__content__bottom {
    height: 55%;
    padding: 0 3.5vmin;

    @include tablet-portrait {
      padding: 0 1.5vmin;
    }

    @include desktop {
      height: 50%;
      padding: 0 1.5vmin 0 var(--margin);
      justify-content: space-between;
    }
  }

  .hero__content__title,
  .hero__content__year {
    font-size: 28.75vmin;
    letter-spacing: -0.025em;

    @include font-disp;

    @include desktop {
      font-size: 45vmin;
    }
  }

  .hero__content__year {
    text-align: right;

    @include tablet-portrait {
      margin-top: auto;
    }
  }

  .hero__content__date {
    margin-bottom: 2vmin;
    font-size: 15vmin;

    @include font-serif;

    @include desktop {
      margin-top: 4.5vmin;
      margin-bottom: 0;
    }
  }
}
