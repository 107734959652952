.subnavigation {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-24;

  span {
    margin: 0 var(--space-10);

    @include font-serif;
  }

  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    span {
      margin: 0 var(--space-16);
    }
  }

  @include tablet-landscape {
    margin-bottom: var(--space-36);

    @include text-36;

    .front__hero + & {
      margin-top: var(--space-12);
    }
  }
}

.subnavigation__item__link {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s cubic-bezier(0.87, 0, 0.13, 1);

    @include tablet-portrait {
      height: 2px;
    }
  }

  &.is-active::before {
    transform: scaleX(1);
  }
}
