.related {
  padding-top: var(--space-95);

  @include grid-base;

  @include desktop {
    padding-top: var(--space-150);
  }
}

.related__heading,
.related__entries {
  grid-column: 1 / -1;
}

.related__heading {
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-36;

  @include tablet-landscape {
    grid-column: span 5;

    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}
