.calendar-module__header,
.calendar-module__item__wrapper {
  @include grid-base;
}

.calendar-module__items,
.calendar-module__item {
  width: 100%;
}

.calendar-module__header {
  margin-bottom: var(--space-63);
}

.calendar-module__header__title,
.calendar-module__header__desc {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }
}

.calendar-module__heading {
  @include font-disp;
  @include text-36;

  @include tablet-landscape {
    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.calendar-module__header__desc {
  @include font-serif;
  @include text-24;

  em {
    font-style: italic;
  }
}

.calendar-module__link {
  margin: var(--space-24) 0;
}

.calendar-module__item {
  border-top: 1px solid currentColor;

  @include text-12;

  @include tablet-portrait {
    @include text-16;
  }
}

.calendar-module__item__wrapper {
  padding: var(--space-12) 0 var(--space-16);
  text-decoration: none;
}

.calendar-module__item__column--exhibition {
  @include font-disp;
  @include text-18;

  @include tablet-portrait {
    @include text-24;
  }
}

.calendar-module__item__column {
  &:first-child,
  &:nth-child(2) {
    grid-column: span 3;
    margin-bottom: var(--space-12);

    @include tablet-portrait {
      grid-column: span 6;
    }

    @include tablet-landscape {
      margin-bottom: 0;
    }
  }

  &:first-child {
    @include tablet-landscape {
      max-width: 8ch;
      grid-column: span 2;
    }

    @include desktop {
      grid-column: span 1;
    }
  }

  &:nth-child(2) {
    text-align: right;

    @include tablet-landscape {
      grid-column: span 2;
      text-align: left;
    }
  }

  &:nth-child(3) {
    grid-column: 1 / -1;
    margin-bottom: var(--space-12);

    @include tablet-landscape {
      grid-column: span 4;
      margin-bottom: 0;
    }

    @include desktop {
      grid-column: span 5;
    }
  }

  &:nth-child(4) {
    grid-column: span 3;

    @include tablet-landscape {
      grid-column: span 2;
    }
  }

  &:nth-child(5) {
    grid-column: span 3;
    text-align: right;

    @include tablet-landscape {
      grid-column: span 2;
      text-align: left;
    }
  }
}
