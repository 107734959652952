.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  pointer-events: none;
  opacity: 0;
  z-index: 100;

  @include grid-base;

  a {
    text-decoration: none;
  }
}

.navigation__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 0;

  @supports (backdrop-filter: blur()) {
    background: transparent;
    backdrop-filter: blur(10px);
  }
}

.navigation__content {
  grid-column: 2 / span 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-black);
  color: var(--color-white);
  overflow-y: scroll;
  z-index: 2;

  @include tablet-portrait {
    grid-column: 5 / span 8;
  }

  @include tablet-landscape {
    grid-column: 6 / span 7;
  }
}

.navigation__top,
.navigation__footer {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: 4fr 3fr;
  }
}

.navigation__primary {
  margin-top: -0.15em;
  padding: var(--space-56) 0 0 var(--margin);

  @include font-disp;
  @include text-36;

  @include phablet {
    padding: var(--space-56) 0 0 var(--margin);
  }

  @include tablet-landscape {
    margin-top: -0.225em;
    padding: var(--margin) 0 0 var(--margin);

    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.navigation__secondary {
  display: flex;
  margin-top: 5em;
  margin-bottom: var(--space-24);
  padding-left: var(--margin);

  @include text-16;

  @include phablet {
    padding-top: var(--margin);
  }

  @include tablet-portrait {
    flex-direction: column;
    margin-top: -0.25em;
    padding-top: var(--space-56);
  }

  @include tablet-landscape {
    padding-top: var(--margin);
  }
}

.navigation__footer__newsletter {
  margin-bottom: var(--space-4);
  padding: 0 0 var(--margin) var(--margin);
}

.navigation__footer__privacy {
  padding-bottom: var(--margin);
}

.navigation__primary__item:not(:last-child) {
  margin-bottom: var(--space-6);
}

.navigation__secondary__list {
  width: 50%;
  margin-right: var(--gutter);

  @include tablet-portrait {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--space-36);
      margin-right: 0;
    }
  }
}

.navigation__secondary__item:not(:last-child) {
  margin-bottom: var(--space-4);
}

.navigation__close {
  position: absolute;
  top: var(--margin);
  right: var(--margin);
  width: 2.375rem;
  height: 1.5rem;
  margin-top: -0.25rem;
  cursor: pointer;
  z-index: 3;

  span {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 2.625rem;
    height: 0.125rem;
    background: var(--color-white);
  }

  span:first-child {
    transform: translateY(-50%) rotate(30deg);
  }

  span:last-child {
    transform: translateY(-50%) rotate(-30deg);
  }
}
