.artist {
  max-width: 100%;
  grid-column: 1 / -1;

  @include grid-base;
}

.artist__name {
  grid-column: 1 / -1;
  margin-bottom: var(--space-24);
  max-width: 25ch;

  @include font-serif;
  @include text-36;

  @include tablet-portrait {
    margin-bottom: var(--space-36);

    @include text-42;
  }

  @include tablet-landscape {
    margin-bottom: var(--space-42);

    @include text-56;
  }

  @include desktop {
    @include text-63;
  }
}

.artist__desc {
  grid-column: 1 / -1;
  margin-bottom: var(--space-36);
  max-width: 56ch;

  @include font-serif;
  @include text-18;

  em {
    font-style: italic;
  }

  @include tablet-landscape {
    grid-column: span 7;
    max-width: 100%;

    @include text-24;
  }
}

.artist__bio {
  grid-column: 1 / -1;
  max-width: 56ch;

  @include text-14;

  @include tablet-landscape {
    grid-column: 10 / span 3;
    max-width: 100%;
  }
}

.artist__buttons {
  display: none;

  @include tablet-portrait {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    margin-top: 0;
  }
}

.artist__button {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  border: 1px solid var(--color-black);
  border-radius: 9999px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M13.364.916l.784-.808 6.27 6.466-6.27 6.466-.784-.808 4.917-5.07H.43V5.986h17.85L13.364.916z' fill='%23000'/%3E%3C/svg%3E");
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.3s cubic-bezier(0, 0.55, 0.45, 1);
  }

  &:first-child {
    transform: rotate(180deg);
  }

  &:last-child {
    margin-left: var(--space-4);
  }

  @include hover-supported {
    &:hover::before {
      transform: translate(-42.5%, -50%);
    }
  }

  &.is-inactive {
    opacity: 0.25;
    pointer-events: none;
  }
}

.artist__works {
  grid-column: 1 / -1;
  width: calc(100% + var(--margin) * 2);
  margin-left: calc(var(--margin) * -1);
  margin-top: var(--space-42);
  padding: 0 var(--margin);
  overflow: hidden;

  @include tablet-portrait {
    margin-top: var(--space-12);
  }
}

.artist__tip + .artist__works {
  margin-top: var(--space-42);
}

.artist__works__inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(((100vw - 2 * var(--margin)) / 6 * 4) - (var(--gutter) - var(--gutter) / 6 * 4)));
  grid-auto-columns: calc((99.999% / 6 * 4));
  grid-auto-flow: column;
  column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: repeat(auto-fit, calc(((100vw - 2 * var(--margin)) / 12 * 5) - (var(--gutter) - var(--gutter) / 12 * 5)));
    grid-auto-columns: calc((99.999% / 12 * 5));
  }
}

.artist__work {
  a {
    text-decoration: none;
  }
}

.artist__work__media {
  margin-bottom: var(--space-12);
  overflow: hidden;

  @include hover-supported {
    &:hover {
      .image {
        transform: scale(1.025);
      }
    }
  }
}

.artist__work__details {
  max-width: 36ch;

  @include text-14;
}

.artist__work__title {
  margin-bottom: var(--space-4);
}

.artist__work__desc {
  @include font-serif;
  @include text-15;
}

.artist__work__credits {
  margin-top: var(--space-6);
}

.artist__work__enquire {
  display: inline-block;
  margin-top: var(--space-24);
}
