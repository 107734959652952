.page__intro,
.page__content {
  @include grid-base;
}

.page__intro {
  margin-bottom: var(--space-56);

  @include tablet-portrait {
    margin-bottom: var(--space-95);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-120);
  }

  @include desktop {
    margin-bottom: var(--space-150);
  }
}

.page__intro__title,
.page__intro__text {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }
}

.page__intro__title {
  margin-bottom: -0.125em;
  margin-bottom: var(--space-36);

  @include font-disp;
  @include text-36;

  @include tablet-portrait {
    margin-bottom: 0;

    @include text-42;
  }

  @include tablet-landscape {
    @include text-63;
  }
}

.page__intro__text {
  @include font-serif;
  @include text-24;
}

.page__content {
  row-gap: var(--space-36);

  @include tablet-landscape {
    row-gap: var(--space-42);
  }

  @include tablet-landscape {
    row-gap: var(--space-56);
  }
}

.page__text,
.page__image,
.page__video,
.page__quote,
.page__logos,
.page__slider,
.page__form {
  grid-column: 1 / -1;
}

.page__text--left,
.page__image--left,
.page__video--left,
.page__quote--left,
.page__form--left {
  @include tablet-portrait {
    grid-column: 1 / span 6;
  }
}

.page__text--right,
.page__image--right,
.page__video--right,
.page__quote--right,
.page__form--right {
  @include tablet-portrait {
    grid-column: 7 / span 6;
  }
}

.page__text {
  @include text-16;

  p:not(:last-of-type) {
    margin-bottom: var(--space-24);
  }

  p:not(.small) + p.small {
    margin-top: var(--space-42);
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  h2 {
    margin-bottom: var(--space-24);

    @include font-serif;
    @include text-24;

    &:not(:first-child) {
      margin-top: var(--space-36);
    }

    @include tablet-landscape {
      @include text-36;
    }
  }

  h3 {
    margin-bottom: var(--space-14);
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: var(--space-24);
    }
  }

  ul,
  ol {
    margin-bottom: var(--space-24);
    margin-left: 0.75em;

    li {
      margin-bottom: 0.5em;
      padding-left: 0.25em;
    }
  }

  ul {
    list-style-type: "—";
  }

  ol {
    list-style-type: decimal;
  }

  .small {
    @include font-sans;
  }

  .page--vip & {
    @include font-serif;
  }

  @include tablet-portrait {
    h3 {
      margin-bottom: var(--space-16);

      &:not(:first-child) {
        margin-top: var(--space-36);
      }
    }
  }

  @include tablet-landscape {
    @include text-18;
  }
}

.page__section {
  grid-column: 1 / -1;
  padding-top: var(--space-10);
  text-transform: uppercase;
  border-top: 1px solid currentColor;

  @include text-14;

  &:not(:first-child) {
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    @include text-18;
  }
}

.page__logos {
  padding-top: var(--space-10);
  border-top: 1px solid var(--color-black);

  @include grid-base;

  &:not(:last-child) {
    margin-bottom: var(--space-36);
  }

  @include tablet-landscape {
    padding-top: var(--space-16);
  }
}

.page__logos__title {
  grid-column: 1 / -1;
  margin-bottom: var(--space-36);
  text-transform: uppercase;

  @include text-14;

  @include tablet-landscape {
    grid-column: span 4;

    @include text-18;
  }
}

.page__logos__list {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gutter);
  row-gap: var(--space-36);

  @include tablet-portrait {
    grid-template-columns: repeat(4, 1fr);
  }

  @include tablet-landscape {
    grid-column: span 8;
  }
}

.page__logos__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  a {
    display: block;
    width: 100%;
    height: auto;
  }
}
