.journal,
.journal__content {
  align-content: start;

  @include grid-base;
}

.journal__filters,
.journal__content,
.journal__section {
  grid-column: 1 / -1;
}

.journal__filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-24;

  span {
    margin: 0 var(--space-10);

    @include font-serif;
  }

  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    span {
      margin: 0 var(--space-16);
    }
  }

  @include tablet-landscape {
    @include text-36;
  }
}

.journal__filter__link {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s cubic-bezier(0.87, 0, 0.13, 1);

    @include tablet-portrait {
      height: 2px;
    }
  }

  &.is-active::before {
    transform: scaleX(1);
  }
}

.journal__tagname {
  grid-column: 1 / -1;
  display: flex;
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-24;

  @include tablet-landscape {
    @include text-36;
  }
}

.journal__section + .journal__section {
  margin-top: var(--space-63);

  @include tablet-portrait {
    margin-top: var(--space-95);
  }

  @include tablet-landscape {
    margin-top: var(--space-120);
  }

  @include desktop {
    margin-top: var(--space-150);
  }
}

.journal__section--entries + .journal__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    margin-top: var(--space-56);
  }

  @include desktop {
    margin-top: var(--space-63);
  }
}

.journal__section--entries-single + .journal__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--gutter);
  }
}

.journal__section--theme {
  width: calc(100% + 2 * var(--margin));
  margin-left: calc(var(--margin) * -1);
}

.journal__section--entries + .journal__section--theme {
  margin-top: var(--space-42);

  @include tablet-portrait {
    margin-top: var(--space-63);
  }

  @include tablet-landscape {
    margin-top: var(--space-95);
  }

  @include desktop {
    margin-top: var(--space-120);
  }
}

.journal__section--theme + .journal__section--entries {
  margin-top: var(--space-36);

  @include tablet-portrait {
    margin-top: var(--space-42);
  }

  @include tablet-landscape {
    margin-top: var(--space-63);
  }

  @include desktop {
    margin-top: var(--space-95);
  }
}

.journal__section--quote {
  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}
