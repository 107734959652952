.login,
.login__form {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login {
  flex-direction: column;
}

.login__form {
  width: 100%;
  margin-bottom: var(--space-36);

  @include tablet-portrait {
    max-width: 25rem;
  }

  @include desktop {
    margin-bottom: var(--space-95);
  }
}

.login__heading {
  margin-bottom: var(--space-10);

  @include font-serif;
  @include text-36;

  @include tablet-portrait {
    @include text-56;
  }

  @include desktop {
    margin-bottom: var(--space-16);

    @include text-95;
  }
}

.login__text {
  margin-bottom: var(--space-36);

  @include font-serif;
  @include text-18;

  @include tablet-portrait {
    @include text-24;
  }

  @include desktop {
    margin-bottom: var(--space-42);
  }
}

.login__info {
  max-width: 42ch;

  @include text-14;

  @include tablet-portrait {
    max-width: 25rem;
  }
}
