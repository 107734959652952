.theme-section {
  padding: var(--space-36) var(--margin) var(--space-63);
  background: var(--color-black);
  color: var(--color-white);
}

.theme-section__header,
.theme-section__content {
  @include grid-base;
}

.theme-section__header {
  margin-bottom: var(--space-56);

  @include tablet-portrait {
    margin-bottom: var(--space-95);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-120);
  }

  @include desktop {
    margin-bottom: var(--space-150);
  }
}

.theme-section__title,
.theme-section__desc {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }
}

.theme-section__title {
  margin-bottom: var(--space-24);

  @include tablet-portrait {
    margin-bottom: 0;
  }
}

.theme-section__title__heading {
  @include font-disp;
  @include text-36;

  @include tablet-portrait {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.theme-section__title__link {
  margin: var(--space-24) 0;
}

.theme-section__desc {
  @include font-serif;
  @include text-24;

  em {
    font-style: italic;
  }
}

.theme-section__entries,
.theme-section__quote {
  grid-column: 1 / -1;
}

.theme-section__entries + .theme-section__entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    margin-top: var(--space-56);
  }

  @include desktop {
    margin-top: var(--space-63);
  }
}

.theme-section__entries--single + .theme-section__entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--gutter);
  }
}

.theme-section__quote,
.theme-section__quote + .theme-section__entries {
  margin-top: var(--space-63);

  @include tablet-portrait {
    margin-top: var(--space-95);
  }

  @include tablet-landscape {
    margin-top: var(--space-120);
  }

  @include desktop {
    margin-top: var(--space-150);
  }
}

.theme-section__quote {
  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}
