.exhibitor {
  @include grid-base;
}

.exhibitor__intro {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 7;
    margin-bottom: var(--space-63);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-95);
  }

  @include desktop {
    margin-bottom: var(--space-150);
  }
}

.exhibitor__intro__title {
  margin-bottom: var(--space-36);
  margin-top: -0.125em;

  @include font-disp;
  @include text-42;

  @include tablet-landscape {
    @include text-63;
  }

  @include desktop {
    margin-bottom: var(--space-42);

    @include text-95;
  }
}

.exhibitor__intro__desc {
  margin-bottom: var(--space-36);

  @include font-serif;
  @include text-24;

  em {
    font-style: italic;
  }

  @include tablet-landscape {
    @include text-36;
  }
}

.exhibitor__intro__details {
  line-height: 1.3;

  a {
    text-decoration: none;
  }

  @include tablet-landscape {
    display: flex;
    flex-wrap: wrap;
  }
}

.exhibitor__intro__address {
  @include tablet-portrait {
    margin-bottom: var(--space-36);
  }
}

.exhibitor__intro__links {
  margin-top: var(--space-16);
  margin-bottom: var(--space-36);

  @include tablet-landscape {
    margin-top: 0;
    margin-left: var(--space-36);
  }
}

.exhibitor__intro__contact {
  width: 100%;
  margin-bottom: var(--space-42);
}

.exhibitor__image {
  grid-column: 1 / -1;
  margin-bottom: var(--space-56);

  @include tablet-portrait {
    grid-column: span 5;
    margin-bottom: var(--space-63);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-95);
  }

  @include desktop {
    margin-bottom: var(--space-150);
  }
}

.exhibitor__curation {
  grid-column: 1 / -1;
  margin-bottom: var(--space-56);

  @include grid-base;

  @include tablet-landscape {
    margin-bottom: var(--space-120);
  }
}

.exhibitor__curation__title {
  grid-column: 1 / -1;
  margin-bottom: var(--space-24);

  @include font-disp;
  @include text-36;

  @include tablet-landscape {
    grid-column: span 5;
    margin-bottom: 0;

    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.exhibitor__curation__desc {
  grid-column: 1 / -1;

  @include text-18;

  @include tablet-landscape {
    grid-column: span 7;

    @include font-disp;
    @include text-24;
  }
}

.exhibitor__curation__images {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gutter);
  row-gap: var(--space-24);
  margin-top: var(--space-36);

  @include tablet-portrait {
    grid-template-columns: repeat(4, 1fr);
    row-gap: var(--space-36);
    margin-top: var(--space-42);
  }

  @include tablet-landscape {
    row-gap: var(--space-42);
  }

  @include desktop {
    row-gap: var(--space-56);
  }
}

.exhibitor__curation__image {
  width: 100%;
  margin-bottom: var(--space-36);
  align-self: start;

  @include tablet-landscape {
    margin-bottom: var(--space-42);
  }

  @include desktop {
    margin-bottom: var(--space-56);
  }
}

.exhibitor__content,
.exhibitor__content__artist,
.exhibitor__content__image,
.exhibitor__content__quote,
.exhibitor__content__video,
.exhibitor__content__text {
  grid-column: 1 / -1;
}

.exhibitor__content,
.exhibitor__content__artist,
.exhibitor__content__text {
  @include grid-base;
}

.exhibitor__content__quote {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}

.exhibitor__content__text {
  @include font-serif;
  @include text-16;

  & > * {
    grid-column: 1 / -1;
  }

  p:not(:last-of-type) {
    margin-bottom: var(--space-24);
  }

  em {
    font-style: italic;
  }

  @include tablet-portrait {
    & > * {
      grid-column: 3 / span 8;
    }
  }

  @include tablet-landscape {
    @include text-18;

    & > * {
      grid-column: 4 / span 6;
    }
  }
}

.exhibitor__content__artist,
.exhibitor__content__image,
.exhibitor__content__quote,
.exhibitor__content__video,
.exhibitor__content__text {
  &:not(:last-child) {
    margin-bottom: var(--space-63);
  }

  @include tablet-landscape {
    &:not(:last-child) {
      margin-bottom: var(--space-150);
    }
  }
}

.exhibitor__video__caption,
.exhibitor__video__credits {
  .video-player--portrait + &,
  .video-player--portrait + & + & {
    @include tablet-portrait {
      @include grid-base;

      * {
        display: block;
        grid-column: 4 / span 6;
      }
    }

    @include tablet-landscape {
      * {
        grid-column: 5 / span 4;
      }
    }
  }
}

.exhibitor__related {
  grid-column: 1 / -1;
}
