.footer {
  width: 100%;
  padding: var(--space-95) var(--margin) var(--gutter);
  overflow: hidden;
  z-index: 1;

  @include desktop {
    padding: var(--space-150) var(--gutter) var(--gutter);
  }
}

.footer__sponsors {
  width: 100%;
  margin-bottom: var(--space-42);
}

.footer__sponsors,
.sponsors,
.sponsors__content,
.sponsors__group,
.sponsors__group__items {
  display: flex;
  align-items: center;
}

.sponsors {
  min-width: min-content;
  overflow: visible;
  color: var(--color-black);

  .main__wrapper--dark & {
    color: var(--color-white);
  }
}

.sponsors__group {
  margin-right: var(--space-24);

  @include tablet-portrait {
    margin-right: var(--space-56);
  }
}

.sponsors__group__title {
  margin-right: var(--space-16);
  text-transform: uppercase;

  @include font-disp;
  @include text-18;

  @include tablet-portrait {
    margin-right: var(--space-36);

    @include text-24;
  }
}

.main__wrapper--dark .sponsors__group__items {
  filter: invert(1);
}

.sponsors__group__item {
  width: 6.5rem;
  height: auto;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }

  @include tablet-portrait {
    width: 8.5rem;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.footer__content {
  grid-template-areas:
    "newsletter newsletter newsletter newsletter newsletter newsletter"
    "navigation navigation navigation social social social"
    "privacy privacy privacy privacy privacy privacy"
    "copyright copyright copyright copyright copyright copyright";
  position: relative;
  color: currentColor;

  @include grid-base;
  @include text-16;

  a {
    text-decoration: none;
  }

  .main__wrapper--dark & {
    color: var(--color-white);
  }

  @include tablet-portrait {
    grid-template-areas:
      "newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter newsletter"
      "navigation navigation navigation navigation navigation navigation social social social social social social";
  }

  @include tablet-landscape {
    grid-template-areas:
      "newsletter newsletter newsletter newsletter . . navigation navigation navigation social social social";
    row-gap: var(--space-42);
  }

  @include desktop {
    grid-template-areas:
      "newsletter newsletter newsletter newsletter . . . . navigation navigation social social";
    row-gap: var(--space-42);
  }
}

.footer--expanded .footer__content {
  grid-template-areas:
    "newsletter newsletter newsletter newsletter newsletter newsletter"
    "navigation navigation navigation social social social"
    "hours hours hours hours hours hours"
    "address address address address address address"
    "privacy privacy privacy privacy privacy privacy"
    "copyright copyright copyright copyright copyright copyright";

  @include tablet-portrait {
    grid-template-areas:
      "newsletter newsletter newsletter newsletter newsletter newsletter . . . . . ."
      "navigation navigation navigation social social social hours hours hours hours hours hours"
      "address address address address address address copyright copyright copyright privacy privacy privacy";
  }

  @include desktop {
    grid-template-areas:
      "newsletter newsletter newsletter newsletter hours hours hours . navigation navigation social social"
      "address address address address address address . . copyright copyright privacy privacy";
  }
}

.footer__newsletter {
  grid-area: newsletter;
}

.footer__newsletter__heading {
  @include font-serif;
  @include text-24;

  @include tablet-landscape {
    @include text-36;
  }
}

.footer__newsletter__form {
  width: 100%;
  display: flex;
  margin-top: var(--space-24);
  margin-bottom: var(--space-56);

  @include desktop {
    margin-top: var(--space-120);
    margin-bottom: 0;
  }
}

.footer__newsletter__button {
  margin: 0;
}

.footer__hours {
  grid-area: hours;
  margin-bottom: var(--space-63);
}

.footer__navigation {
  grid-area: navigation;
  margin-bottom: var(--space-63);

  @include tablet-landscape {
    margin-bottom: 0;
  }
}

.footer__social {
  grid-area: social;
}

.footer__navigation li:not(:last-child),
.footer__social li:not(:last-child) {
  margin-bottom: var(--space-4);
}


.footer__address {
  grid-area: address;
  margin-bottom: var(--space-24);

  @include tablet-portrait {
    margin-bottom: 0;
  }
}

.footer__privacy {
  grid-area: privacy;

  @include tablet-portrait {
    grid-area: social;
    align-self: end;
  }
}

.footer--expanded .footer__privacy {
  grid-area: privacy;

  @include tablet-portrait {
    justify-self: end;
  }

  @include desktop {
    justify-self: start;
  }
}

.footer__privacy {
  grid-area: privacy;

  @include tablet-portrait {
    grid-area: navigation;
    align-self: end;
  }
}

.footer__copyright {
  grid-area: copyright;

  @include tablet-portrait {
    grid-area: social;
    align-self: end;
  }
}

.footer--expanded .footer__privacy {
  grid-area: privacy;
}

.footer--expanded .footer__copyright {
  grid-area: copyright;
}

.footer--expanded .footer__navigation,
.footer--expanded .footer__social {
  margin-bottom: var(--space-63);
}
