.fui-form {
  width: 100%;

  .fui-row:not(:first-child) {
    margin-top: var(--space-24);
  }

  .fui-btn-container {
    margin-top: var(--space-42);
  }

  .fui-input-container {
    display: flex;
  }

  label,
  legend {
    @include text-12;

    &:not(.fui-radio-label) {
      display: block;
      margin-bottom: var(--space-4);
      text-transform: uppercase;
    }
  }

  legend:not(.fui-radio-label) {
    margin-bottom: var(--space-16);

    @include tablet-portrait {
      margin-bottom: var(--space-10);
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='tel'],
  input[type='url'] {
    flex: 1;
    min-width: 0;
    margin: 0;
    padding: var(--space-6) 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-radius: 0;
    color: currentColor;
    border-color: currentColor;
    background: transparent;
    outline: none;

    @include font-sans;
    @include text-16;

    &::placeholder {
      color: currentColor;
      opacity: 0.25;
    }
  }

  .fui-layout-horizontal .fui-layout-wrap {
    display: flex;
    flex-direction: column;

    .fui-radio {
      display: flex;
    }

    .fui-radio:not(:last-child) {
      margin: 0 0 0.75rem 0;
    }

    @include tablet-portrait {
      flex-direction: row;
      align-items: center;

      .fui-radio {
        justify-content: center;
      }

      .fui-radio:not(:last-child) {
        margin: 0 0.75rem 0 0;
      }
    }
  }

  .fui-type-radio-buttons {
    margin: var(--space-36) 0;
  }

  input[type='radio'] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: transparent;
    margin: 0 0.325rem 0 0;
    font: inherit;
    color: currentColor;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid currentColor;
    border-radius: 999999px;
    transform: translateY(-0.075em);

    &::before {
      content: '';
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 999999px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em currentColor;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  button[type='submit'] {
    display: block;
    position: relative;
    width: max-content;
    padding: var(--space-8) var(--space-36);
    border: 1px solid var(--color-black);
    color: var(--color-black);
    border-radius: 0;
    background: transparent;
    text-transform: uppercase;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s cubic-bezier(0.87, 0, 0.13, 1);
    z-index: 1;

    @include font-sans;
    @include text-12;

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: var(--color-black);
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.2s cubic-bezier(0.87, 0, 0.13, 1);
      z-index: -1;
    }

    @include hover-supported {
      &:hover {
        color: var(--color-white);
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
}

.fui-alert-success {
  @include font-serif;
  @include text-18;
}

.fui-alert-error,
.fui-error-message {
  color: var(--color-red);
}

.fui-alert {
  margin-bottom: var(--space-24);
}

.fui-error-message {
  margin-top: var(--space-10);

  @include text-12;
}
