@font-face {
  font-family: 'NeueHaasGroteskText Pro';
  src: url('../assets/fonts/NHaasGroteskTXPro-56It.woff2') format('woff2'),
    url('../assets/fonts/NHaasGroteskTXPro-56It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskText Pro';
  src: url('../assets/fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
    url('../assets/fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDisp Pro';
  src: url('../assets/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
    url('../assets/fonts/NHaasGroteskDSPro-55Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueHaasGroteskDisp Pro';
  src: url('../assets/fonts/NHaasGroteskDSPro-56It.woff2') format('woff2'),
    url('../assets/fonts/NHaasGroteskDSPro-56It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Victor Serif';
  src: url('../assets/fonts/VictorSerif-Bold.woff2') format('woff2'),
    url('../assets/fonts/VictorSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Victor Serif';
  src: url('../assets/fonts/VictorSerif-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/VictorSerif-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Victor Serif';
  src: url('../assets/fonts/VictorSerif-Regular.woff2') format('woff2'),
    url('../assets/fonts/VictorSerif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Victor Serif';
  src: url('../assets/fonts/VictorSerif-RegularItalic.woff2') format('woff2'),
    url('../assets/fonts/VictorSerif-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
