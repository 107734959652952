.article__header {
  margin-bottom: var(--space-56);

  @include grid-base;

  .article--centered & {
    text-align: center;
  }

  @include tablet-landscape {
    margin-bottom: var(--space-95);
  }
}

.article__header--fullscreen,
.article__header--sidebyside {
  height: calc(var(--vh, 1vh) * 100);
}

.article__header--fullscreen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: var(--space-36);

  .article__title,
  .article__subtitle,
  .article__byline {
    color: var(--color-white);
    z-index: 1;

    @supports (mix-blend-mode: difference) {
      mix-blend-mode: difference;
    }
  }
}

.article__header--sidebyside {
  padding-top: var(--space-95);
  grid-auto-rows: 1fr;

  @include tablet-portrait {
    padding-top: var(--space-150);
  }

  @include tablet-landscape {
    align-content: stretch;
    padding-top: var(--space-200);
  }
}

.article__header__background {
  position: absolute;
  top: 0;
  left: calc(var(--margin) * -1);
  width: calc(100% + 2 * var(--margin));
  height: 100%;
  overflow: hidden;

  .image-wrapper,
  .image {
    width: 100%;
    height: 100%;
  }

  .image-wrapper {
    padding-top: 0;
  }

  .image {
    object-fit: cover;
    object-position: center center;
  }
}

.article__header__col {
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
    padding-bottom: var(--space-24);
  }

  @include tablet-landscape {
    grid-column: span 6;
    height: 100%;

    &:first-child {
      justify-content: flex-end;
    }
  }
}

.article__header__image {
  width: 100%;
  height: 100%;

  .image-wrapper,
  .image {
    width: 100%;
    height: 100%;
  }

  .image-wrapper {
    padding-top: 0;
  }

  .image {
    object-fit: contain;
    object-position: bottom right;
  }

  @include tablet-portrait {
    width: calc(100% + var(--margin));
  }
}

.article__content {
  row-gap: var(--space-36);

  @include grid-base;

  @include tablet-portrait {
    .article--default & {
      row-gap: var(--space-63);
    }

    .article--centered & {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: var(--gutter);
      row-gap: var(--space-63);
    }
  }
}

.article__title {
  max-width: 25ch;
  grid-column: 1 / -1;

  @include font-serif;
  @include text-24;

  .article--centered & {
    margin-left: auto;
    margin-right: auto;
    max-width: 18ch;

    @include text-36;
  }

  .article__header--fullscreen & {
    @include font-disp;
    @include text-36;
  }

  .article__header--sidebyside & {
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  @include tablet-portrait {
    .article--default & {
      @include text-36;
    }

    .article--centered &,
    .article__header--fullscreen & {
      @include text-56;
    }
  }

  @include tablet-landscape {
    .article--default & {
      @include text-56;
    }

    .article--centered &,
    .article__header--fullscreen & {
      @include text-95;
    }

    .article__header--sidebyside & {
      @include text-63;
    }
  }
}

.article__subtitle {
  grid-column: 1 / -1;
  margin-top: var(--space-10);
  max-width: 36ch;

  @include font-disp;
  @include text-16;

  .article__header--fullscreen & {
    @include font-serif;
  }

  .article__header--sidebyside & {
    text-align: left;
  }

  .article--centered & {
    margin-left: auto;
    margin-right: auto;
  }

  @include tablet-portrait {
    margin-top: var(--space-24);

    @include text-18;

    .article--centered & {
      @include text-24;
    }
  }

  @include tablet-landscape {
    .article--centered & {
      margin-top: var(--space-36);
    }

    @include text-24;
  }

  @include desktop {
    .article--centered & {
      max-width: 30ch;

      @include text-36;
    }
  }
}

.article__byline {
  grid-column: 1 / -1;
  margin-top: var(--space-36);

  .article__header--sidebyside & {
    text-align: left;
  }

  @include desktop {
    .article__header:not(.article__header--fullscreen) & {
      margin-top: var(--space-36);
    }
  }
}

.article__byline__authordate {
  @include text-14;

  @include tablet-portrait {
    @include text-16;
  }

  @include tablet-landscape {
    @include text-17;
  }
}

.article__byline__tags {
  @include font-serif;
  @include text-15;

  a {
    text-decoration: none
  }

  @include tablet-portrait {
    @include text-17;
  }

  @include tablet-landscape {
    @include text-18;
  }
}

.article__text,
.article__image,
.article__quote,
.article__video,
.article__notes,
.article__qa,
.article__slider {
  grid-column: 1 / -1;
}

.article__text,
.article__image,
.article__video,
.article__notes,
.article__qa {
  @include tablet-portrait {
    .article--centered & {
      width: calc((99.999% / 2) - (var(--gutter) - var(--gutter) / 2));
    }
  }
}

.article--centered .article__video--portrait {
  @include tablet-portrait {
    width: calc((99.999% / 12 * 6) - (var(--gutter) - var(--gutter) / 12 * 6));
    margin-left: calc(
      (99.999% / 12 * 3) - (var(--gutter) - var(--gutter) / 12 * 3)
    );
    margin-right: calc(
      (99.999% / 12 * 3) - (var(--gutter) - var(--gutter) / 12 * 3)
    );
  }

  @include tablet-landscape {
    width: calc((99.999% / 12 * 4) - (var(--gutter) - var(--gutter) / 12 * 4));
    margin-left: calc(
      (99.999% / 12 * 4) - (var(--gutter) - var(--gutter) / 12 * 4)
    );
    margin-right: calc(
      (99.999% / 12 * 4) - (var(--gutter) - var(--gutter) / 12 * 4)
    );
  }
}

.article__text,
.article__qa {
  * {
    max-width: 35rem;
  }

  p:not(:last-of-type) {
    margin-bottom: var(--space-24);
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  h2 {
    @include text-18;
  }

  h2,
  h3 {
    margin-bottom: var(--space-14);
    font-weight: bold;

    &:not(:first-child) {
      margin-top: var(--space-24);
    }
  }

  ul,
  ol {
    margin-bottom: var(--space-24);
    margin-left: 0.75em;

    li {
      margin-bottom: 0.5em;
      padding-left: 0.25em;
    }
  }

  ul {
    list-style-type: '—';
  }

  ol {
    list-style-type: decimal;
  }

  @include tablet-portrait {
    h2 {
      @include text-24;
    }

    .article--centered & {
      margin: 0 var(--gutter);

      * {
        margin-left: auto;
        margin-right: auto;
      }
    }

    h3 {
      margin-bottom: var(--space-16);

      &:not(:first-child) {
        margin-top: var(--space-36);
      }
    }
  }

  @include tablet-landscape {
    @include text-18;
  }
}

.article__text {
  @include font-serif;
  @include text-16;

  @include desktop {
    @include text-18;
  }
}

.article__image--center {
  grid-column: 1 / -1;
}

.article__image--left,
.article__video--left,
.article__text--left,
.article__notes--left,
.article__qa--left {
  @include tablet-portrait {
    .article--default & {
      grid-column: 1 / span 6;
    }
  }
}

.article__image--right,
.article__video--right,
.article__text--right,
.article__notes--right,
.article__qa--right {
  @include tablet-portrait {
    .article--default & {
      grid-column: 7 / span 6;
    }
  }
}

.article__video--left.article__video--portrait {
  @include tablet-portrait {
    .article--default & {
      grid-column: 1 / span 6;
    }
  }

  @include tablet-landscape {
    .article--default & {
      grid-column: 1 / span 4;
    }
  }
}

.article__video--right.article__video--portrait {
  @include tablet-portrait {
    .article--default & {
      grid-column: 7 / span 6;
    }
  }

  @include tablet-landscape {
    .article--default & {
      grid-column: 9 / span 4;
    }
  }
}

.article__image__desc,
.article__video__desc {
  margin-top: var(--space-12);
}

.article__quote {
  margin: var(--space-42) 0;

  @include tablet-portrait {
    margin: var(--space-56) 0;

    .article--default & {
      grid-column: 2 / -2;
    }

    .article--centered & {
      width: calc(
        (99.999% / 12 * 10) - (var(--gutter) - var(--gutter) / 12 * 10)
      );
    }
  }
}

.article__quote--left,
.article__quote--right {
  @include tablet-portrait {
    .article--default & {
      margin: 0;
    }
  }
}

.article__quote--left {
  @include tablet-portrait {
    .article--default & {
      grid-column: 1 / span 5;
    }
  }
}

.article__quote--right {
  @include tablet-portrait {
    .article--default & {
      grid-column: 8 / span 5;
    }
  }
}

.article--centered .article__slider {
  width: 100%;
}

.article__notes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: var(--gutter);
  align-content: start;

  @include tablet-landscape {
    grid-template-columns: 1fr;
  }
}

.article__note {
  grid-column: 2 / span 4;
  display: grid;
  align-content: start;
  grid-template-columns: 1fr;
  column-gap: var(--gutter);

  &:not(:last-child) {
    margin-bottom: var(--space-24);
  }

  @include tablet-portrait {
    grid-column: 3 / span 4;
  }

  @include tablet-landscape {
    grid-column: 1 / -1;
    grid-template-columns: repeat(3, 1fr);
  }
}

.article__note--image:not(:last-child) {
  margin-bottom: var(--space-36);

  @include tablet-landscape {
    margin-bottom: var(--space-24);
  }
}

.article__note__image {
  margin-bottom: var(--space-10);

  @include tablet-landscape {
    margin-bottom: 0;
  }
}

.article__note__text {
  max-width: 36ch;

  p {
    @include text-12;
  }

  p:not(:last-child) {
    margin-bottom: var(--space-12);
  }

  em {
    font-style: italic;
  }

  h4 {
    margin-bottom: var(--space-10);

    @include font-serif;
    @include text-16;
  }

  @include tablet-landscape {
    grid-column: 2 / span 2;

    p {
      @include text-14;
    }

    h4 {
      @include text-18;
    }
  }
}

.article__qa__question {
  @include font-sans;
  @include text-15;

  @include tablet-landscape {
    @include text-17;
  }
}

.article__qa__answer {
  @include font-serif;
  @include text-16;

  @include tablet-landscape {
    @include text-18;
  }
}

.article__qa__question,
.article__qa__answer {
  &::before {
    content: attr(data-initials);
  }
}

.article__qa__answer + .article__qa__question {
  margin-top: var(--space-36);
}

.article__text--right + .article__notes--left,
.article__image--right + .article__notes--left,
.article__qa--right + .article__notes--left,
.article__text--center + .article__notes,
.article__image--center + .article__notes {
  margin-top: var(--space-56);

  @include tablet-portrait {
    & + .article__notes--right {
      margin-top: var(--space-56);
    }
  }
}
