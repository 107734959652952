.programme,
.programme__header,
.programme__content,
.programme__list,
.programme__item__header,
.calendar,
.calendar__header,
.calendar__content,
.calendar__list,
.calendar__item__header {
  @include grid-base;
}

.programme__header,
.programme__content,
.programme__filters-container,
.programme__labels,
.programme__list,
.programme__item,
.calendar__header,
.calendar__content,
.calendar__filters-container,
.calendar__labels,
.calendar__list,
.calendar__item {
  grid-column: 1 / -1;
}

.programme:not(.programme--vip) {
  color: var(--color-white);
}

.programme__header,
.calendar__header {
  margin-bottom: var(--space-95);
}

.programme__heading,
.programme__desc,
.calendar__heading,
.calendar__desc {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }
}

.programme__heading,
.calendar__heading {
  margin-top: -0.125em;
  margin-bottom: var(--space-36);

  @include font-disp;
  @include text-36;

  @include tablet-portrait {
    margin-bottom: 0;

    @include text-42;
  }

  @include tablet-landscape {
    @include text-63;
  }
}

.programme__desc,
.calendar__desc {
  @include font-serif;
  @include text-24;

  em {
    font-style: italic;
  }
}

.programme__desc__button,
.calendar__desc__button {
  margin-top: var(--space-36);
}

.programme__filters,
.calendar__filters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-10) var(--space-10);

  &:not(:last-child) {
    margin-bottom: var(--space-36);
  }

  @include tablet-landscape {
    grid-template-columns: repeat(4, 1fr);

    &:not(:last-child) {
      margin-bottom: var(--space-24);
    }
  }

  @include desktop {
    grid-template-columns: repeat(6, 1fr);
  }
}

.programme__filter,
.calendar__filter {
  width: 100%;
  min-width: 0;
  grid-column: span 1;
}

.programme__labels,
.calendar__labels {
  display: none;

  @include tablet-landscape {
    margin-bottom: var(--space-8);

    @include grid-base;
  }
}

.programme__label,
.calendar__label {
  text-transform: uppercase;

  @include text-12;
}

.programme__label,
.programme__item__column {
  &:first-child,
  &:nth-child(2) {
    grid-column: span 3;
    margin-bottom: var(--space-12);

    @include tablet-portrait {
      grid-column: span 6;
    }

    @include tablet-landscape {
      grid-column: span 1;
      margin-bottom: 0;
    }
  }

  &:nth-child(2) {
    text-align: right;

    @include tablet-landscape {
      text-align: inherit;
    }
  }

  &:nth-child(3) {
    grid-column: 1 / -1;
    margin-bottom: var(--space-4);

    @include tablet-landscape {
      grid-column: 4 / span 6;
      margin-bottom: 0;
    }
  }

  &:last-child {
    grid-column: 1 / -1;

    @include tablet-landscape {
      grid-column: 11 / span 2;
    }
  }
}

.calendar__label,
.calendar__item__column {
  &:first-child,
  &:nth-child(2) {
    grid-column: span 3;
    margin-bottom: var(--space-12);

    @include tablet-portrait {
      grid-column: span 6;
    }

    @include tablet-landscape {
      margin-bottom: 0;
    }
  }

  &:first-child {
    @include tablet-landscape {
      max-width: 8ch;
      grid-column: span 2;
    }

    @include desktop {
      grid-column: span 1;
    }
  }

  &:nth-child(2) {
    text-align: right;

    @include tablet-landscape {
      grid-column: span 2;
      text-align: left;
    }
  }

  &:nth-child(3) {
    grid-column: 1 / -1;
    margin-bottom: var(--space-12);

    @include tablet-landscape {
      grid-column: span 4;
      margin-bottom: 0;
    }

    @include desktop {
      grid-column: span 5;
    }
  }

  &:nth-child(4) {
    grid-column: span 3;

    @include tablet-landscape {
      grid-column: span 2;
    }
  }

  &:nth-child(5) {
    grid-column: span 3;
    text-align: right;

    @include tablet-landscape {
      grid-column: span 2;
      text-align: left;
    }
  }
}

.programme__content,
.calendar__content {
  margin-top: var(--space-63);
}

.programme__item,
.calendar__item {
  border-top: 1px solid currentColor;

  @include text-14;

  @include tablet-portrait {
    @include text-16;
  }
}

.programme__item__header,
.calendar__item__header {
  padding: var(--space-12) 0 var(--space-16);
  user-select: none;
  cursor: pointer;

  @include text-12;

  @include tablet-portrait {
    @include text-16;
  }
}

.programme__item__column--date {
  @include tablet-landscape {
    max-width: 4ch;
  }
}

.programme__item__column--title,
.calendar__item__column--exhibition {
  @include font-disp;
  @include text-18;

  @include tablet-portrait {
    @include text-24;
  }
}

.programme__item__wrap,
.calendar__item__wrap {
  max-height: 0;
  overflow: hidden;
}

.programme__item__content,
.calendar__item__content {
  margin: var(--space-24) 0 var(--space-63);

  @include grid-base;
}

.programme__item__desc,
.calendar__item__desc {
  grid-column: 1 / -1;
  order: 2;

  @include tablet-portrait {
    grid-column: span 4;
    order: 1;
  }
}

.programme__item__button,
.calendar__item__button {
  margin-top: var(--space-42);
}

.programme__item__images,
.calendar__item__images {
  grid-column: 1 / -1;
  margin-bottom: var(--space-36);
  order: 1;

  @include tablet-portrait {
    grid-column: span 5;
    order: 2;
  }
}

.programme__item__image + .programme__item__image,
.calendar__item__image + .calendar__item__image {
  margin-top: var(--space-12);
}

.programme__registration,
.calendar__registration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  pointer-events: none;
  opacity: 0;
  z-index: 100;

  @include grid-base;

  a {
    text-decoration: none;
  }
}

.programme__registration__overlay,
.calendar__registration__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.75);
  z-index: 0;

  @supports (backdrop-filter: blur()) {
    background: transparent;
    backdrop-filter: blur(10px);
  }
}

.programme__registration__slider,
.calendar__registration__slider {
  grid-column: 2 / span 5;
  background: var(--color-black);
  color: var(--color-white);
  position: relative;
  z-index: 2;
  overflow-y: scroll;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background: var(--color-black);
    box-shadow: 0 0.5rem 5px var(--color-black);
    z-index: 1;
  }

  @include tablet-portrait {
    grid-column: 6 / span 7;
  }

  @include tablet-landscape {
    grid-column: 8 / span 5;
  }

  @include desktop {
    grid-column: 9 / span 4;
  }
}

.programme__registration__content,
.programme__registration__success,
.programme__registration__error,
.calendar__registration__content,
.calendar__registration__success,
.calendar__registration__error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.programme__registration__content,
.calendar__registration__content {
  position: relative;
  padding: var(--space-56) var(--margin) var(--margin);

  @include phablet {
    padding: var(--space-95) var(--margin) var(--margin);
  }
}

.programme__registration__success,
.programme__registration__error,
.calendar__registration__success,
.calendar__registration__error {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  padding: var(--margin);

  @include font-serif;
  @include text-18;
}

.programme__registration__heading,
.calendar__registration__heading {
  position: absolute;
  top: 0.5rem;
  left: var(--margin);
  z-index: 2;

  @include font-serif;
  @include text-18;
}

.programme__registration__details,
.calendar__registration__details {
  margin-bottom: var(--space-63);
}

.programme__registration__title,
.calendar__registration__title {
  margin-bottom: var(--space-6);

  @include font-disp;
  @include text-24;
}

.programme__registration__time,
.programme__registration__location,
.calendar__registration__time,
.calendar__registration__location {
  @include text-16;
}

.programme__registration__form,
.calendar__registration__form {
  display: flex;
  flex-direction: column;
}

.programme__registration__input + .programme__registration__input,
.calendar__registration__input + .calendar__registration__input {
  margin-top: var(--space-24);
}

.programme__registration__button,
.calendar__registration__button {
  margin-top: var(--space-36);
}

.programme__registration__info,
.calendar__registration__info {
  margin-top: auto;

  @include text-16;
}

.programme__registration__close,
.calendar__registration__close {
  position: absolute;
  top: var(--margin);
  right: var(--margin);
  width: 2.375rem;
  height: 1.5rem;
  margin-top: -0.25rem;
  cursor: pointer;
  z-index: 2;

  span {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 2.625rem;
    height: 0.125rem;
    background: var(--color-white);
  }

  span:first-child {
    transform: translateY(-50%) rotate(30deg);
  }

  span:last-child {
    transform: translateY(-50%) rotate(-30deg);
  }
}

.programme__empty,
.calendar__empty {
  grid-column: 1 / -1;

  @include font-disp;
  @include text-18;

  @include tablet-portrait {
    @include text-24;
  }
}
