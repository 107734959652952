@mixin grid-base {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
  }
}
