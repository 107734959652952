.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: var(--color-white);
  opacity: 0;
  pointer-events: none;
  z-index: 150;
}

.lightbox__close {
  position: absolute;
  top: var(--margin);
  right: var(--margin);
  width: 2.375rem;
  height: 1.5rem;
  margin-top: -0.25rem;
  cursor: pointer;
  z-index: 3;

  span {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 2.625rem;
    height: 0.125rem;
    background: var(--color-black);
  }


  span:first-child {
    transform: translateY(-50%) rotate(30deg);
  }

  span:last-child {
    transform: translateY(-50%) rotate(-30deg);
  }
}


.lightbox__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--space-56) var(--space-36) var(--space-120);
  opacity: 0;
  pointer-events: none;
}

.lightbox__slide__mediawrapper,
.lightbox__slide__original {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox__slide__mediawrapper {
  position: relative;
}

.lightbox__slide__media {
  cursor: zoom-in;

  .image-wrapper {
    position: relative;
    width: inherit;
    height: inherit;
    padding-top: 0;
  }

  .image {
    position: relative;
  }
}

.lightbox__slide__loader {
  position: absolute;
  width: 75px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -150%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-black);
  border-radius: 9999px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 5000;
}

.lightbox__slide__dots {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-white);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -16px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-white);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 16px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-white);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 1s;
  }
}

@keyframes dotFlashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.5;
  }
}

.lightbox__slide__original {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-white);
  opacity: 0;
  pointer-events: none;
  z-index: 200;
}

.lightbox__slide__tooltip,
.lightbox__slide__details {
  position: absolute;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-50%);
  text-align: center;
}

.lightbox__slide__tooltip {
  top: 0;
  height: var(--space-56);
  text-transform: uppercase;

  @include text-10;
}

.lightbox__slide__details {
  bottom: 0;
  height: var(--space-120);

  @include text-14;

  a {
    text-decoration: none;
  }
}

.lightbox__slide__desc {
  margin-bottom: var(--space-14);

  @include font-serif;
  @include text-15;
}
