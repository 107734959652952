.highlights {
  @include grid-base;
}

.highlights__header,
.highlights__items {
  grid-column: 1 / -1;
}

.highlights__header {
  margin-bottom: var(--space-36);

  @include tablet-portrait {
    margin-bottom: var(--space-42);
  }
}

.highlights__heading {
  margin-bottom: var(--space-10);

  @include font-disp;
  @include text-36;

  @include tablet-landscape {
    grid-column: span 5;

    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.highlights__items {
  display: grid;
  column-gap: var(--gutter);
  grid-template-columns: repeat(1, 1fr);

  @include tablet-landscape {
    grid-template-columns: repeat(4, 1fr);

    &.highlights__items--3cols {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.highlights__item {
  border-top: 1px solid var(--color-black);

  &:last-child {
    border-bottom: 1px solid var(--color-black);
  }

  @include tablet-landscape {
    border-bottom: 1px solid var(--color-black);
  }
}

.highlights__item__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: var(--gutter);
  padding: var(--space-14) 0;
  height: 100%;
  text-decoration: none;

  @include tablet-portrait {
    padding: var(--space-16) 0;
  }
}

.highlights__item__main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.highlights__item__date,
.highlights__item__time,
.highlights__item__location {
  @include text-14;

  @include tablet-portrait {
    @include text-16;
  }
}

.highlights__item__time {
  margin-bottom: var(--space-4);
}

.highlights__item__title {
  margin-bottom: var(--space-16);

  @include font-disp;
  @include text-18;

  @include tablet-portrait {
    margin-bottom: var(--space-36);

    @include text-24;
  }

  @include tablet-landscape {
    margin-bottom: var(--space-63);
  }
}

.highlights__item__location {
  margin-top: auto;
}
