.header {
  position: fixed;
  width: 100%;
  padding: var(--space-16) var(--margin) 0;
  color: var(--color-black);
  opacity: 0;
  z-index: 50;

  @include grid-base;

  @supports (mix-blend-mode: difference) {
    color: var(--color-white);
    mix-blend-mode: difference;
  }

  a {
    text-decoration: none;
  }
}

.header__logo,
.header__toggle {
  grid-column: span 3;

  @include tablet-portrait {
    grid-column: span 2;
  }
}

.header__logo {
  margin-top: -0.15em;
  letter-spacing: -0.025em;
  transform-origin: top left;
  will-change: transform;

  @include font-disp;
  @include text-24;

  @include tablet-landscape {
    @include text-36;
  }
}

.header__navigation {
  display: none;

  @include tablet-portrait {
    grid-column: span 8;
    display: flex;
    justify-content: center;
    margin-top: -0.15em;

    @include text-16;
  }
}

.header__navigation__list {
  display: flex;
  justify-content: space-between;
}

.header__navigation__item {
  margin: 0 0.5rem;
}

.header__navigation__link {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background: currentColor;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.4s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &.is-active::before {
    transform: scaleX(1);
  }
}

@keyframes toggle-top {
  50% {
    transform: scaleX(0);
    transform-origin: right;
  }

  50.0001% {
    transform: scaleX(0);
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@keyframes toggle-bottom {
  50% {
    transform: scaleX(0);
    transform-origin: left;
  }

  50.0001% {
    transform: scaleX(0);
    transform-origin: right;
  }

  100% {
    transform: scaleX(1);
    transform-origin: right;
  }
}

.header__toggle {
  justify-self: end;
  position: relative;
  width: 2.625rem;
  height: 0.625rem;
  cursor: pointer;

  span {
    position: absolute;
    right: 0;
    width: 100%;
    height: 0.125rem;
    background: var(--color-black);
    transform-origin: right;

    @supports (mix-blend-mode: difference) {
      background: var(--color-white);
    }
  }

  span:last-child {
    top: 0.5rem;
    transform-origin: left;
  }

  @include hover-supported {
    &:hover span:first-child {
      animation: toggle-top 1s cubic-bezier(0.85, 0, 0.15, 1);
    }

    &:hover span:last-child {
      animation: toggle-bottom 1s cubic-bezier(0.85, 0, 0.15, 1);
    }
  }
}
