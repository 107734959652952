.entries {
  display: grid;
  column-gap: var(--gutter);
  row-gap: var(--space-24);
  grid-template-rows: masonry;

  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    row-gap: var(--space-36);
  }

  @include tablet-landscape {
    row-gap: var(--space-42);
  }
}

.entries--single {
  grid-template-columns: 1fr;
}

.entries:not(.entries--4cols) {
  @include desktop {
    row-gap: var(--space-63);
  }
}

.entries--4cols {
  @include desktop {
    row-gap: var(--space-56);
  }
}

.entries--2cols,
.entries--3cols,
.entries--4cols,
.entries--offset {
  grid-template-columns: repeat(2, 1fr);
}

.entries--3cols {
  @include tablet-portrait {
    grid-template-columns: repeat(3, 1fr);
  }
}

.entries--4cols,
.entries--offset {
  @include tablet-portrait {
    grid-template-columns: repeat(4, 1fr);
  }
}

.entry {
  align-self: start;
  display: flex;
  flex-direction: column;

  .entries--single & {
    @include tablet-portrait {
      @include grid-base;
    }
  }

  .entries--left &:first-child,
  .entries--right &:last-child {
    grid-column: span 2;
  }
}

.entry__media {
  display: block;
  margin-bottom: var(--space-8);
  order: 1;

  .entries--single & {
    grid-column: 1 / -1;

    @include tablet-portrait {
      grid-column: span 8;
      margin-bottom: 0;
    }
  }

  @include tablet-portrait {
    margin-bottom: var(--space-12);
  }
}

.entry__text {
  display: flex;
  flex-direction: column;
  order: 2;

  .entries--single & {
    @include tablet-portrait {
      grid-column: span 4;
      margin-top: auto;
    }
  }
}

@include hover-supported {
  .entry__media:hover,
  .entry__text:hover + .entry__media {
    .image,
    .video {
      transform: scale(1.025);
    }
  }
}

.entry__category {
  margin-bottom: var(--space-4);

  @include text-12;

  @include tablet-portrait {
    margin-bottom: var(--space-6);

    @include text-14;
  }

  @include tablet-landscape {
    @include text-16;
  }
}

.entry__heading {
  max-width: 36ch;

  @include font-serif;
  @include text-18;

  @include tablet-landscape {
    @include text-24;
  }

  @include desktop {
    @include text-36;

    .entries--single & {
      @include text-56;
    }

    .entries--2cols & {
      @include text-42;
    }
  }
}

.entry__meta {
  margin-top: var(--space-24);
}

.entry__meta__authordate {
  @include font-sans;
  @include text-12;

  @include tablet-portrait {
    @include text-14;
  }

  @include tablet-landscape {
    @include text-16;
  }
}

.entry__meta__tags {
  @include font-serif;
  @include text-13;

  @include tablet-portrait {
    @include text-15;
  }

  @include tablet-landscape {
    @include text-17;
  }
}
