.quote {
  width: 100%;
  text-align: center;
}

.quote--right,
.quote--left {
  @include tablet-portrait {
    text-align: left;
  }
}

.quote__text {
  margin-bottom: var(--space-36);

  @include font-serif;
  @include text-42;

  em {
    font-style: italic;
  }

  @include tablet-portrait {
    @include text-56;
  }

  @include tablet-landscape {
    @include text-63;
  }

  @include desktop {
    @include text-95;
  }
}

.quote--small .quote__text {
  @include text-24;

  @include tablet-portrait {
    @include text-36;
  }

  @include tablet-landscape {
    @include text-42;
  }

  @include desktop {
    @include text-56;
  }
}

.quote--smaller .quote__text,
.quote--left .quote__text,
.quote--right .quote__text {
  @include text-24;

  @include tablet-landscape {
    @include text-36;
  }

  @include desktop {
    @include text-42;
  }
}

.quote__author {
  @include font-serif;
  @include text-16;

  @include tablet-portrait {
    @include text-18;
  }
}

.quote__source {
  @include text-15;

  @include tablet-portrait {
    @include text-17;
  }
}
