/* Font families */

@mixin font-sans {
  font-family: 'NeueHaasGroteskText Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin font-disp {
  font-family: 'NeueHaasGroteskDisp Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin font-serif {
  font-family: 'Victor Serif', 'Times New Roman', 'Times', serif;
}

/* Font sizes */
@mixin text-10 {
  font-size: 0.625rem;
  line-height: 1.4;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.520833vw;
  }
}

@mixin text-12 {
  font-size: 0.75rem;
  line-height: 1.4;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.625vw;
  }
}

@mixin text-13 {
  font-size: 0.812rem;
  line-height: 1.4;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.677083vw;
  }
}

@mixin text-14 {
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.729167vw;
  }
}

@mixin text-15 {
  font-size: 0.938rem;
  line-height: 1.3;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.78125vw;
  }
}

@mixin text-16 {
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.833333vw;
  }
}

@mixin text-17 {
  font-size: 1.062rem;
  line-height: 1.3;
  letter-spacing: 0.02em;

  @include desktop-large {
    font-size: 0.885417vw;
  }
}

@mixin text-18 {
  font-size: 1.125rem;
  line-height: 1.3;

  @include desktop-large {
    font-size: 0.9375vw;
  }
}

@mixin text-24 {
  font-size: 1.5rem;
  line-height: 1.2;

  @include desktop-large {
    font-size: 1.25vw;
  }
}

@mixin text-36 {
  font-size: 2.25rem;
  line-height: 1.1;

  @include desktop-large {
    font-size: 1.875vw;
  }
}

@mixin text-42 {
  font-size: 2.625rem;
  line-height: 1.1;

  @include desktop-large {
    font-size: 2.1875vw;
  }
}

@mixin text-56 {
  font-size: 3.5rem;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include desktop-large {
    font-size: 2.916667vw;
  }
}

@mixin text-63 {
  font-size: 3.938rem;
  line-height: 1.1;
  letter-spacing: -0.01em;

  @include desktop-large {
    font-size: 3.28125vw;
  }
}

@mixin text-95 {
  font-size: 5.938rem;
  line-height: 1;
  letter-spacing: -0.02em;

  @include desktop-large {
    font-size: 4.947917vw;
  }
}

@mixin text-142 {
  font-size: 8.875rem;
  line-height: 1;
  letter-spacing: -0.02em;

  @include desktop-large {
    font-size: 7.395833vw;
  }
}
