.exhibitors-slider {
  width: calc(100% + var(--margin) * 2);
  margin-left: calc(var(--margin) * -1);
  padding: 0 var(--margin);
  overflow: hidden;

  @include grid-base;

  a {
    text-decoration: none;
  }
}

.exhibitors-slider__heading {
  grid-column: 1 / -1;
  margin-bottom: var(--space-6);

  @include font-disp;
  @include text-36;

  @include tablet-landscape {
    grid-column: span 5;

    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.exhibitors-slider__buttons {
  display: none;

  @include tablet-landscape {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-24);
  }
}

.exhibitors-slider__button {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  border: 1px solid var(--color-black);
  border-radius: 9999px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 14'%3E%3Cpath d='M13.364.916l.784-.808 6.27 6.466-6.27 6.466-.784-.808 4.917-5.07H.43V5.986h17.85L13.364.916z' fill='%23000'/%3E%3C/svg%3E");
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 0.2s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &:first-child {
    transform: rotate(180deg);
  }

  &:last-child {
    margin-left: var(--space-4);
  }

  @include hover-supported {
    &:hover::before {
      transform: translate(-42.5%, -50%);
    }
  }
}

.exhibitors-slider__list {
  grid-column: 1 / -1;
}

.exhibitors-slider__list__inner {
  display: grid;
  width: max-content;
  grid-template-columns: repeat(auto-fit, calc(((100vw - 2 * var(--margin)) / 2) - (var(--gutter) - var(--gutter) / 2)));
  grid-auto-columns: calc(((100vw - 2 * var(--margin)) / 2) - (var(--gutter) - var(--gutter) / 2));
  grid-auto-flow: column;
  column-gap: var(--gutter);

  @include tablet-portrait {
    grid-template-columns: repeat(auto-fit, calc(((100vw - 2 * var(--margin)) / 4) - (var(--gutter) - var(--gutter) / 4)));
    grid-auto-columns: calc(((100vw - 2 * var(--margin)) / 4) - (var(--gutter) - var(--gutter) / 4));
  }
}

.exhibitors-slider__item {
  grid-column: 1 / 1;
  grid-row: 1;
}

.exhibitors-slider__item__media {
  margin-bottom: var(--space-12);
}

.exhibitors-slider__item__title {
  @include text-16;

  @include tablet-portrait {
    @include font-disp;
    @include text-18;
  }

  @include desktop {
    @include text-24;
  }
}
