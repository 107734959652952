.theme {
  background: var(--color-black);
  color: var(--color-white);

  @include grid-base;
}

.theme__header,
.theme__section {
  grid-column: 1 / -1;
}

.theme__header {
  margin-bottom: var(--space-56);

  @include grid-base;

  @include tablet-portrait {
    margin-bottom: var(--space-95);
  }

  @include tablet-landscape {
    margin-bottom: var(--space-120);
  }

  @include desktop {
    margin-bottom: var(--space-150);
  }
}

.theme__header__title,
.theme__header__desc {
  grid-column: 1 / -1;

  @include tablet-portrait {
    grid-column: span 6;
  }
}

.theme__header__title {
  margin-bottom: var(--space-24);
  margin-top: -0.125em;

  @include font-disp;
  @include text-36;

  @include tablet-portrait {
    margin-bottom: 0;
  }

  @include tablet-landscape {
    @include text-42;
  }

  @include desktop {
    @include text-63;
  }
}

.theme__header__desc {
  @include font-serif;
  @include text-24;

  em {
    font-style: italic;
  }
}

.theme__section + .theme__section {
  margin-top: var(--space-63);

  @include tablet-portrait {
    margin-top: var(--space-95);
  }

  @include tablet-landscape {
    margin-top: var(--space-120);
  }

  @include desktop {
    margin-top: var(--space-150);
  }
}

.theme__section--entries + .theme__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--space-36);
  }

  @include tablet-landscape {
    margin-top: var(--space-56);
  }

  @include desktop {
    margin-top: var(--space-63);
  }
}

.theme__section--entries-single + .theme__section--entries {
  margin-top: var(--space-24);

  @include tablet-portrait {
    margin-top: var(--gutter);
  }
}

.theme__section--quote {
  a {
    text-decoration: none;
  }

  @include tablet-portrait {
    grid-column: 2 / -2;
  }
}
